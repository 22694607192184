import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { IbottaStyles } from "../styles/IbottaStyles"
import { Link } from "gatsby"
import { ibotta1, ibotta2, ibotta3, ibotta_logo1, ibotta_logo2, ibotta_logo3, ibotta_logo4, ibotta_logo5,ibotta_logo6, ibotta_logo7, ibotta_gif, ibotta_landing} from "../utils/imageUpload"

const IbottaPage = () => (
  <Layout>
    <SEO title="Ibotta" />
    <IbottaStyles>
      <div className="landing">
        <div className="img-container">
          <img src={ibotta_landing}></img>
        </div>
        <div className="title-container">
          <h1 className="title BundaySlabBold">Save $1 now when you shop with Ibotta!</h1>
        </div>
      </div>
      <div className="section info-section">
        <div className="text-container">
          <p className="BundaySlabBold">We created Savorly's party bites and gratins to make it easier to get together and enjoy time with friends and family. Whether gathering around a holiday table, catching up over drinks and appetizers, or a weekly video chat - we believe that life is better when we're together.</p>
          <br/>
          <p className="BundaySlabBold">Each of our products was made with convenience and high quality in mind. You can heat and serve Savorly products at a moment's notice without sacrificing on ingredients or experience for yourself or your guests.</p>
        </div>
        <div className="gif-wrapper">
          <img src={ibotta_gif}></img>
        </div>
      </div>
      <div className="section logo-section">
        <p className="title">Find us & save at:</p>
        <div className="logo-container">
        <div className="row">
        <img src={ibotta_logo5}></img>
          <img src={ibotta_logo6}></img>
          <img src={ibotta_logo7}></img>
        </div>
        <div className="row">
          <img src={ibotta_logo1}></img>
          <img src={ibotta_logo2}></img>
          <img src={ibotta_logo3}></img>
          <img src={ibotta_logo4}></img>
        </div>
        </div>
     
        <Link to="/find-us">Store Finder</Link>
      </div>
      <div className="section phone-section">
        <p className="title">Details on how to save:</p>
        <div className="wrapper">
        <div className="item">
        <img src={ibotta1}></img>
              <p className="step">Step 1</p>
              <p className="instruction">Download the app</p>
       
          </div>

          <div className="item">
          <img src={ibotta2}></img>
              <p className="step">Step 2</p>
              <p className="instruction">Shop at your favorite retailer</p>
       
          </div>
          <div className="item">
          <img src={ibotta3}></img>
              <p className="step">Step 3</p>
              <p className="instruction">Follow the instructions to snap a pic and earn your cash back</p>
        
          </div>
      </div>
      </div>
    </IbottaStyles>
  </Layout>
)

export default IbottaPage
