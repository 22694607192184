import styled from "styled-components"


export const IbottaStyles = styled.div`
  color: #092F57;
  font-family: "BundaySlab-SemiBoldUp", sans-serif;

  .landing{
    background: var(--beige);
    height:70vh;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  .title-container{
    max-width: 500px;
    .title{
    text-align: center;
    font-size: 4.5vw;
    font-weight: 600;
    }
  }

  .img-container{
    height: 100%;
    img{
      height:100%;
    }
  }
  

  .section{
    display: flex;
    align-items: center;
    padding: 40px 20px;

    .title{
      text-transform: uppercase;
      font-size: 40px;
      font-weight: 400;
      font-family: "FuturaPTCond-Medium", sans-serif;
    }
  }

  .info-section{
    background: #FCC077;
    justify-content: center;
    padding-bottom: 0;

    .text-container{
      text-align: center;
      width: 50%;
      p{
        font-weight: 600;
        font-size: 20px;
      }
    }

    .gif-wrapper{
      width:50%;
    }
  }

  .logo-section{
    background: var(--beige);
    display: flex;
    flex-direction: column;
    justify-content:space-between;
    align-items: center;
    padding: 45px 0;

    .logo-container{
      display: flex;
      flex-direction: column;

      .row{
        text-align:center;

        img{
          margin: 20px 40px;
          height: 60px;
        }
      }
    }
    a {
      text-decoration: none;
      background: #092F57;
      color:white;
      border-radius: 30px;
      padding: 10px 40px;
      text-transform: uppercase;
    }
  }

  .phone-section{
    background:#fcdf52;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .wrapper{
      display:flex;
      justify-content: center;
      width: 100%;
      .item{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        text-align:center;
        font-size: 25px;
        width: 270px;
        img{
          max-height: 150px;
        }
        .step {
          font-size:30px;
          text-transform: uppercase;
        }

        .instruction{
          min-height: 50px;
        }

      }
    }
  }
  @media(max-width: 425px){
    .section{
      .title{
        font-size: 30px;
      }
    }
    .phone-section{
      .wrapper{
        flex-direction:column;
        align-items: center;

        .item{
          margin: 20px 0;
        }
      }
    }
  }
  @media(max-width: 425px){

    .landing {
  position: relative;

  .img-container{
    opacity: .6;
  }
  .title-container {
  position: absolute;

  .title{
    font-size: 50px;
  }
}
}

/* Bottom right text */

    .info-section{
      flex-direction: column;

      .text-container{
        width: 90%;
      }
      .gif-wrapper{
        width: 90%;
      }
      }
  }

  @media(min-width: 1024px){
    .info-section{
      height: 500px;
      justify-content:space-evenly;
      .text-container{
        width: 40%;
      }
      .gif-wrapper{
        height: 100%;
        width: auto;

        img{
          height:100%;
        }
      }
      }
  }
;
`
